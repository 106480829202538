<template>
  <b-card title="Actualizar turno">
    <b-row>
      <b-col>
        <div class="form-group">
          <label>Código</label>
          <input
            v-model="form.cod"
            type="text"
            class="form-control form-control-sm"
            placeholder="Código"
          />
        </div>
      </b-col>
      <b-col md="4">
        <div class="form-group">
          <label>Recurso</label>
          <input
            v-model="form.placa"
            type="text"
            max="6"
            class="form-control form-control-sm"
            placeholder="Placa"
          />
        </div>
      </b-col>
      <b-col md="4">
        <div class="form-group">
          <label>Observación</label>
          <input
            v-model="form.descripcion"
            type="text"
            class="form-control form-control-sm"
            placeholder="Example: 14:00 a 22:00"
          />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <label>Jornada</label>
        <select class="form-control form-control-sm" v-model="form.tipo">
          <option :value="null">Seleccione</option>
          <option :value="item.id" v-for="(item, idx) in journey" :key="idx">
            {{ item.nombre }}
          </option>
        </select>
      </b-col>
      <b-col>
        <div class="form-group">
          <label>Fecha inicio</label>
          <input
            v-model="form.fecha"
            type="date"
            class="form-control form-control-sm"
            placeholder="Fecha"
          />
        </div>
      </b-col>
      <b-col>
        <div class="form-group">
          <label>Fecha fin</label>
          <input
            v-model="form.fecha_fin"
            :min="form.fecha"
            type="date"
            class="form-control form-control-sm"
            placeholder="Fecha fin"
          />
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="d-flex justify-content-end">
        <div class="form-group">
          <router-link class="btn btn-sm btn-circle" :to="{ name: 'turns' }">
            <i class="la la-reply"></i> Cancelar
          </router-link>
          <button class="btn btn-sm btn-primary" @click="update()">
            <i class="la la-save"></i>
            {{ loading ? "Cargando..." : "Actualizar turno" }}
          </button>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import axios from "axios";
import toastr from "toastr";
import moment from "moment";
export default {
  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      journey: [],
      form: {
        cod: null,
        placa: null,
        descripcion: null,
        fecha: null,
        fecha_fin: null,
        tipo: null,
      },
    };
  },
  mounted() {
    this.loadJourn();
    this.loadTurn();
  },
  filters: {
    performanceTurn(values) {
      var response = values.split("-").join(" y ").split("|").join(" a ");
      return response;
    },
  },
  methods: {
    loadTurn() {
      axios
        .get(`/turns/${this.id}`)
        .then((res) => {
          const [data] = res.data;
          console.log(data);
          this.form = data;
          this.form.cod = data.codigo;
          this.form.fecha = moment(data.fecha).format("YYYY-MM-DD");
          this.form.fecha_fin = moment(data.fecha_fin).format("YYYY-MM-DD");
          this.form.descripcion = data.observacion
            .split("-")
            .join(" y ")
            .split("|")
            .join(" a ");
          this.form.tipo = data.jornada.id;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    loadJourn() {
      axios
        .get(`/tipo-jornadas`)
        .then((res) => {
          this.journey = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    update() {
      if (
        this.form.cod &&
        this.form.descripcion &&
        this.form.fecha &&
        this.form.fecha_fin
      ) {
        this.loading = true;
        this.form.descripcion = this.form.descripcion
          .split(" y ")
          .join("-")
          .split(" a ")
          .join("|");
        let data = [this.form];


        axios
          .put(`/turns/${this.id}`, {data: data})
          .then((res) => {
            if (res.data.message == "success") {
              this.$emit("loadTurns", true);
              toastr.success(res.data.information);
              this.loadTurn();
            } else {
              toastr.info(res.data.information);
            }
            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            console.log(e);
          });
      } else {
        if (!this.form.cod) {
          toastr.info("El código - brigada es obligatorio");
        }
        if (!this.form.descripcion) {
          toastr.info("La observación es obligatoria");
        }
        if (!this.form.fecha) {
          toastr.info("La fecha de inicio es obligatoria");
        }
        if (!this.form.fecha_fin) {
          toastr.info("La fecha de finalización es obligatoria");
        }
      }
    },
  },
};
</script>
